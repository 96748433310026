
.Root {
    /* position: relative; */
    bottom: 0;
    max-width: 1000px;
    padding: 20px;
    height: auto;
    margin: 20px auto 0;
    border-top: 1px solid #e5e5ea;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.Root .links button {
    
    padding-bottom: 0;
    padding-top: 0;
}

.Root a {
    text-decoration: none;
}

.Root div {
    padding-bottom: 20px;
}

.Root p {
    padding: 0;
    margin: 0
}

@media only screen and (max-width: 600px) {
    .Root {
        flex-direction: column-reverse;
    }
    .Root .links a {
        text-align: center;
    }
}