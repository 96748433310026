.Root {
    min-height: calc(100vh - 152px);
}

.img {
    width: 40%;
}

.base {
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}