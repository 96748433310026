.Root {
    max-width: 720px;
    min-height: calc(100vh - 152px);
    margin: 0 auto;
    margin-bottom: 60px;
    padding: 0 10px;
    text-align: justify;
    text-indent: 50px;
    font-size: 1.3em;
    font-family: 'Crimson Text', serif;
}
.Root ol li {
    text-indent: 0;
}
.parallax {  
    /* Full height */
    height: 650px;
    width: 100%;
    margin-top: -20px;
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallaxPodcasts {  
    background-position: top;
    background-size: auto 75%;
    background-color: black;
}

.date {
    width: 100%;
    text-align: right;
    padding-bottom: 20px;
}

h1 {
    text-align: center;
    text-indent: 0;
    padding: 40px 0;
}


@media only screen and (max-width: 600px) {
    .Root {
      font-size: 1.4em;
    }
    .parallax {
        height: 500px;
        background-position: top;
    }
    h1 {
        font-size: 1.6em;
    }
}