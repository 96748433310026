.mobile-dropdown-panel-group {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
}

.dropdown-panel .rs-panel-body {
    padding: 0%;
}

.dropdown-panel .radio-group {
    border-radius: 0%;
    border-width: 0ch;
}

.dropdown-panel .radio-group label {
    width: 100%;
    text-align: left;
    font-size: 21px;
    padding-left: 20px;
}

.dropdown-panel .rs-panel-heading .rs-panel-title span {
    font-size: 21px;;
}