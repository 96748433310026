.Root{
    max-width: 1000px;
    min-height: calc(100vh - 152px);
    margin: 0 auto;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    .Root {
        width: 100%;
    }
}