
.articlePanel {
    margin: 20px;
    cursor: pointer;
    position: relative;
}

.articlePanel div {
    padding: 0;
}

.img {
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    background-color: black;
}

.overlay {
    position: absolute;
    top: 0;
    left: 100%;
    right: 0;
    /* background-color: #008CBA; */
    background-color: black;
    overflow: hidden;
    width: 0;
    height: 200px;
    transition: .5s ease;
}
.description {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: calc(100%-40px);
    text-indent: 75px;
    position: absolute;
    width: 80%;
    top: 5%;
    left: 10%;
}
.articlePanel:hover .overlay {
    width: 100%;
    left: 0;
    right: 100%;
  }

.articlePanel  p {
    text-align: justify;
    text-indent: 40px;
    font-size: 1.2em;
    font-family: 'Crimson Text', serif;
}
.articlePanelTitle {
    padding: 20px;
}

.articlePanelDate {
    float: right;
    padding: 10px 20px;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .articlePanelTitle {
        font-size: 1.4em;
    }

    .articlePanelDate {
        font-size: 1.1em;
    }

    .description {
        font-size: 17px;
        text-indent: 50px;
        width: 90%;
        top: 5%;
        left: 5%;
    }
}