.Root {    
    min-height: calc(100vh - 152px);
}
.LoaderContainer {
    margin: 0;
    text-indent: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -100%;
    transform: translate(-50%, -50%)
}
