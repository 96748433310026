.SideBarMobile {
    display: none;
}

.SideBarDesktop {
    padding: 20px;
    width: 320px;
    float: left;
    margin: 0;
    transition: .5s linear;
}

@media only screen and (max-width: 1025px) {
    .SideBarDesktop {
        width: 250px;
    }
}

@media only screen and (max-width: 800px) {
    .SideBarDesktop {
        width: 215px;
    }
}
@media only screen and (max-width: 650px) {
    .SideBarDesktop {
        width: auto;
    }
}


@media only screen and (max-width: 600px) {
    .SideBarMobile {
        display: block;
        width: 100%;
    }
    .SideBarDesktop {
        display: none;
    }
}