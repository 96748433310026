.Root {
    margin-bottom: 20px;
}
.Icon {
    font-size: 18px !important;
    margin-right: 0 !important;
}

.LogoText {
    font-size: 1.2em;
    font-family: AbrilFatface;
    display: inline;
    margin-left: 10px;
}


@media only screen and (max-width: 600px) {
    .Icon {
        font-size: 25px !important;
    }
    .LogoText {
        display: none;
    }    
}