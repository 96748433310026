.Articles {
    /* max-width: 680px; */
    margin-bottom: 60px;
    /* float: right; */
}

.ArticlesPanel {
    overflow: hidden;
}

@media only screen and (max-width: 900px) {
    .Articles {
        width: 100%;
    }
}